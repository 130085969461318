import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Table",
  "type": "Content"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Table`}</strong>{` component used to render a table.`}</p>
    <hr></hr>
    <h2>{`Examples`}</h2>
    <h3>{`Simple table`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true",
        "live": "true"
      }}>{`<Table>
  <TableHead>
    <TableHeadRow>
      <TableHeadCell align="left">No.</TableHeadCell>
      <TableHeadCell>First Name</TableHeadCell>
      <TableHeadCell>Last Name</TableHeadCell>
      <TableHeadCell align="right">Age</TableHeadCell>
    </TableHeadRow>
  </TableHead>
  <TableBody>
    <TableRow>
      <TableCell align="left">1</TableCell>
      <TableCell important>John</TableCell>
      <TableCell>Doe</TableCell>
      <TableCell align="right" highlight>19</TableCell>
    </TableRow>
    <TableRow>
      <TableCell align="left">2</TableCell>
      <TableCell important>Thomas</TableCell>
      <TableCell>Edison</TableCell>
      <TableCell align="right" highlight>55</TableCell>
    </TableRow>
    <TableRow>
      <TableCell align="left">3</TableCell>
      <TableCell important>Abraham</TableCell>
      <TableCell>Lincoln</TableCell>
      <TableCell align="right" highlight>45</TableCell>
    </TableRow>
  </TableBody>
</Table>
`}</code></pre>
    <h3>{`Table with inputs`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true",
        "live": "true"
      }}>{`<Table>
  <TableHead>
    <TableHeadRow>
      <TableHeadCell align="left">No.</TableHeadCell>
      <TableHeadCell>First Name</TableHeadCell>
      <TableHeadCell>Last Name</TableHeadCell>
      <TableHeadCell>Gender</TableHeadCell>
      <TableHeadCell align="right" colSpan={2}>Actions</TableHeadCell>
    </TableHeadRow>
  </TableHead>
  <TableBody>
    <TableRow>
      <TableCell align="left">1</TableCell>
      <TableCell>
        <TextInput defaultValue="John" />
      </TableCell>
      <TableCell>
        <TextInput defaultValue="Doe" />
      </TableCell>
      <TableCell>
        <Select
          options={[ { id: "male", label: "Male" }, { id:"female", label: "Female"}]}
          value="male"
        />
      </TableCell>
      <TableCell align="right" highlight>
        <Button variant={ButtonVariant.Secondary}>Edit</Button>
      </TableCell>
      <TableCell align="right">
        <IconButton tooltip="Delete" icon={<Icons.BiX/>} />
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell align="left">2</TableCell>
      <TableCell>
        <TextInput defaultValue="Thomas" />
      </TableCell>
      <TableCell>
        <TextInput defaultValue="Edison" />
      </TableCell>
      <TableCell>
        <Select
          options={[ { id: "male", label: "Male" }, { id:"female", label: "Female"}]}
          value="male"
        />
      </TableCell>
      <TableCell align="right" highlight>
        <Button variant={ButtonVariant.Secondary}>Edit</Button>
      </TableCell>
      <TableCell align="right">
        <IconButton tooltip="Delete" icon={<Icons.BiX/>} />
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell align="left">3</TableCell>
      <TableCell>
        <TextInput defaultValue="Abraham" />
      </TableCell>
      <TableCell>
        <TextInput defaultValue="Lincoln" />
      </TableCell>
      <TableCell>
        <Select
          options={[ { id: "male", label: "Male" }, { id:"female", label: "Female"}]}
          value="male"
        />
      </TableCell>
      <TableCell align="right" highlight>
        <Button variant={ButtonVariant.Secondary}>Edit</Button>
      </TableCell>
      <TableCell align="right">
        <IconButton tooltip="Delete" icon={<Icons.BiX/>} />
      </TableCell>
    </TableRow>
  </TableBody>
</Table>
`}</code></pre>
    <h2>{`Props `}<inlineCode parentName="h2">{`Table`}</inlineCode></h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Table`}</strong>{` passes all props to the underlying `}<inlineCode parentName="p">{`table`}</inlineCode>{` element.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Props `}<inlineCode parentName="h2">{`TableHead`}</inlineCode></h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`TableHead`}</strong>{` passes all props to the underlying `}<inlineCode parentName="p">{`thead`}</inlineCode>{` element.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Props `}<inlineCode parentName="h2">{`TableHeadRow`}</inlineCode></h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`TableHeadRow`}</strong>{` passes all props to the underlying `}<inlineCode parentName="p">{`tr`}</inlineCode>{` element.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Props `}<inlineCode parentName="h2">{`TableHeadCell`}</inlineCode></h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`TableHeadCell`}</strong>{` passes all props to the underlying `}<inlineCode parentName="p">{`th`}</inlineCode>{` element.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`align`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"left" / "center" / "right"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aligns the content to a side`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Props `}<inlineCode parentName="h2">{`TableBody`}</inlineCode></h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`TableBody`}</strong>{` passes all props to the underlying `}<inlineCode parentName="p">{`tbody`}</inlineCode>{` element.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Props `}<inlineCode parentName="h2">{`TableRow`}</inlineCode></h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`TableRow`}</strong>{` passes all props to the underlying `}<inlineCode parentName="p">{`tr`}</inlineCode>{` element.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Props `}<inlineCode parentName="h2">{`TableCell`}</inlineCode></h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`TableCell`}</strong>{` passes all props to the underlying `}<inlineCode parentName="p">{`td`}</inlineCode>{` element.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`align`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"left" / "center" / "right"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aligns the content to a side`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`important`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Render the text as important`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`highlight`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Render the text as highlighted`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      